import styled from "@emotion/styled";
import { MAX_INTEGRATIONS_FLOATING_WIDTH } from "segments/desktop/integrations/layouts/floating-integrations/constants";
import { ITEM_TOTAL_HEIGHT, SLIDER_MARGIN, NUMBER_OF_ROWS } from "./constants";
const HEIGHT = `${(ITEM_TOTAL_HEIGHT + SLIDER_MARGIN) * NUMBER_OF_ROWS}px`;
export const StyledFloatingIntegrationsComponent = styled.div`
  max-width: ${MAX_INTEGRATIONS_FLOATING_WIDTH}px;
  margin: auto;
  height: ${HEIGHT};
  overflow-x: hidden;
`;
